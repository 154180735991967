html, body, #root {
    height: 100%;
    background-color: #fff;
    margin: 0px;
    font-family: 'gothic' !important;
}
.base-color{
    color: #000;
}


.bg-gray {
    background: #f7f7f7;
}

.fix-top{
    position: absolute;
    width: 100%;
    top:0;
}
.fix-btm{
    position: absolute;
    width: 100%;
    bottom:0;
}

.top-nav{
    color: #38220b;
    background: rgba(255,255,255, 0.4);
}

.top-nav .collapse{
    z-index: 9;
}

.top-nav .nav-item .nav-link{
    padding-left: 10px;
}

.top-nav.active{
    background-color: #38220b;
    color : #fff;
}

.top-nav.active .nav-link{
    color: #fff;
}

.top-nav.active .nav-link.active{
    color: #38220b;
    background-color: #fff;
}

.show{
    display: block;
}

.title{
    color : #38220b;
    font-weight: bold;
    font-size: 20px;
}

.hide{
    display: none;
}
.container{
    height: 100%;
    padding: 60px 0 0 0;
    overflow: auto;
    position: relative;
    max-width: 420px;
}
.container.no-pad{
    padding: 0 !important;
}

#landing{
    height: 100%;
    display: block;
    align-items: center;
    background-image: url(../img/bg.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    max-width: 400px;
    margin: auto;
}

#landing .inner{
    width:600px;
    max-width: 100%;
    margin: auto;
    height:100%;
}

#landing .inner .top{
    height: 70vh;
    display: flex;
    align-items: center;
}

#landing .inner .btm{
    height: 20vh;
    display: flex;
    columns: 1;
    align-items: center;
}

#landing .inner .btm div{
    width: 100%;
}

.btn {
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    font-family: 'gothic';
    font-weight: bold;
    padding: 10px 25px;
    border-radius: 30px;
    background: #38220b;
    color: #fff;
}

#landing .btn {
	padding:10px 0px;
	min-width:320px;
	display: block;
    margin: 10px auto;
}

#landing .bg-btn {
    color: #fff;
    border: 2px solid #fff;
	background:#38220b;
}



#login .inner{
    width:600px;
    max-width: 100%;
    margin: auto;
    height:100%;
    display: flex;
    align-items: center;
}

.tmpl-main-btn{
   display: block;
   font-size: 20px;
   margin: 10px auto;
   font-family: 'gothic';
   font-weight: bold;
   width: 200px;
   border-radius: 5px;
   border: none;
}

.tmpl-main-btn.filled{
    background-color:  #38220b;
    color: #fff;
    border: 2px solid #38220b;
}

.tmpl-main-btn.inverse{
    background-color:  #fff;
    color: #38220b;
    border: 2px solid #38220b;
}


#landing .inner .top,#login .inner .top,#register .inner .top{
    width: 100%;
}

#login .inner .btm{
    display: flex;
    height: 20%;
    align-items: center;

}

.landing-logo{
    width:60%;
    display: block;
    margin: 50px auto;
}
.login-logo{
    width:30%;
    display: block;
    margin: 20px auto;
}

.central-logo{
    height: 35px;
    display: block;
    margin: 2px auto;
}

#login, #register{
    height: calc(100% - 60px);
    overflow: auto;
}

#dashboard,#profile,#terms{
    overflow: auto;
}

#login .inner{
    height: calc(100% - 50px);
    display: flex;
    align-items: center;
    padding: 20px;
}

#register .inner{
    padding: 20px;
}

#login .inner h1,#register .inner h1{
    color : #38220b;
    font-family: 'gothic';
    font-weight: bold;
    text-align: center;
    font-size: 25px;
    margin-bottom: 50px;
}

#login .inner .frm-box label,#register .inner .frm-box label, #profile .inner .frm-box label, #subscription .inner .frm-box label{
    color : #38220b;
    font-family: 'gothic';
    font-weight: bold;
    font-size: 13px;
}

#login .inner .frm-box input, #register .inner .frm-box input, #profile .inner .frm-box input, #subscription .inner .frm-box input{
    color : #38220b;
    font-family: 'gothic';
    font-size: 15px;
    background-color: #eff0f2;
}

.sub-input{
    color : #38220b;
    font-family: 'gothic';
    font-size: 15px;
    background-color: #eff0f2;
    border-radius: 5px;
    border : 1px solid #ccc;
    height: 35px;
    line-height: 35px;
    padding: 10px;
}

#profile .inner .frm-box{
    width: 80%;
    margin: auto;
}

#subscription .inner .frm-box{
    width: 90%;
    margin: auto;
}

.tb-form-dd{
    background-color: #eff0f2;
    font-family: 'gothic';
    color : #38220b;
    border: 1px solid #ced4da;
    font-size: 13px;
}

.back-btn,.dropdown-item{
    font-family: 'gothic';
    color : #fff;
    margin: 15px 1px;
}
.dropdown-item{
    color: #38220b;
    font-size: 15px;
}

.cbox span{
    font-family: 'gothic';
    color: #000;
    font-weight: normal;
    font-size: 11px;
}
.cbox a{
    color: #f9a753;
}

.form-links{
    text-align: center;
    margin-top: 30px;
}

.form-links a{
    color: #999;
    font-size: 10px;
    text-decoration: none;
    font-family: 'gothic';
    padding: 0 10px;
}

.form-links .otp-timer{
    color: #999;
    font-size: 10px;
    text-decoration: none;
    font-family: 'gothic';
}

.tool-bar-nl{
    height: 60px;
    background-color: #fff;
    -webkit-box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.75);
box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.75);
}

.tool-bar{
    height: 214px;
}

.tool-bar .central-logo{
    height: 50px;
}
.nav-banner{
    width:100%;
    min-height: 130px;
}

.nav-links{
    text-align: center;
    width: 100%;
    margin: 20px auto;
}

.navs{
    text-decoration: none;
    width: fit-content;
    padding: 2px 15px;
    display: inline-block;
    margin:  5px;
    font-size: 13px;
}

.swal2-title {
    position: relative;
    max-width: 100%;
    margin: 0;
    padding: 0.8em 1em 0;
    color: inherit;
    font-size: 15px !important;
    font-family: "gothic";
}

.reg-success-img{
    display: block;
    width: 50%;
    margin: 50px auto;
}

.reg-success-txt{
    color : #38220b;
    font-family: 'gothic';
    font-weight: bold;
    font-size: 15px;
    text-align: center;
}
.reg-success-btn{
    text-align: center;
    display: block;
    width: 150px;
    text-decoration: none;
}

.wlt-blk{
    padding:10px 5px 0px 10px;
    /*border:2px solid #ccc;*/
    border-radius: 3px;
    width: 95%;
    margin: auto;
}

.points-bar > div {margin-bottom:10px;}
.point-item{padding:10px ; background:#fff;height: 100%; border-radius: 0px; }

.point-item h3 {    font-size: 20px;    color: #38220b;    font-family: 'gothic'; text-align: center;font-weight: bold; text-transform: uppercase;}
.point-item a{    color: #38220b;  text-transform: uppercase;  font-size: 10px;    line-height: normal; text-decoration: none; display: block; text-align: center;font-weight: bold;}


.key-point {    
    align-items: center;     
    display: flex;  
    justify-content: center; 
    background: #efeeee;
    border: 2px solid #fff;
    border-radius: 0px; 
}
.key-point input {    
    font-size: 15px !important;
    background: transparent !important;
    display: block;   
    color:#7c7a7a;
    text-align: center;
    width: calc(100% - 35px) !important;  
    margin: 0 !important;
    border: none;
    height: 40px;
}

.key-point input::placeholder {
    color:#7c7a7a;
}  

.key-point button.circle-bg {  
    height: 30px;   
    width: 30px;  
    background: #38220b;   
    color: #fff;   
    padding: 0;   
    border-radius: 100%;    
    line-height: 30px;
    font-size: 10px;
    border: none;
    margin-left:5px; 
 }

 .nav-tabs .nav-link{
    font-weight: bold;
    font-size: 13px;
    color: #38220b;
    border: none;
 }

img {
    max-width: 100%;
    vertical-align: bottom;
}

img.powered{
    width: 50%;
    margin: 5px auto;
    display: block;
}

 .nav-tabs .nav-link.active{
    color: #fff;
    background-color: #38220b;
    border-bottom: 2px solid #38220b;
 }

 .reward-list{
    margin-bottom: 80px;
 }
.reward-list .row > div {    padding: 0 2px;  margin-bottom: 10px;  }
.reward-item {padding: 15px;    text-align: left;  height:100%;   background: #fff; }
.reward-item h3 {  font-family: 'gothic'; color: #38220b; font-size: 12px; font-weight: bold; padding: 5px 0 7px 0;   }
.reward-item h4 {    font-family: 'gothic';    font-size: 11px; font-weight: bold;   max-width: 80%;  }
.reward-item p {    font-family: 'gothic';    font-size: 9px; font-weight: bold;    max-width: 80%; color: #38220b; }

.reward-item img{
    height: 140px;
    display: block;
    margin: auto;
}

.reward-content { 
    display: flex;
    align-items: center;
 }

 .reward-content .reward-content-inner{
     width: calc(100% - 30px);
     margin-right: 5px;
     padding: 4px;
 } 

 .reward-content .reward-content-inner p span{
    color: #f00;
    padding-left: 5px;
 }

.custom-radio  input {    display: none;  }
.custom-radio label {    font-size: 0; margin:0; }
.custom-radio label:before {
    content: '';
    width: 30px;
    height: 30px;
    background-color: #dee8f1;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}
.custom-radio  input:checked ~ label:before {
   background-color: #38220b;
}
.custom-radio  input:checked ~ label:after { visibility:visible; }
.custom-radio label:after {
	 content: '';
     width: 20px;
     height: 20px;
	 background-image: url('../img/tick.png');
    background-position: center;
    background-size: 100%;
	background-repeat:no-repeat;
	display: block;
    position: absolute;
    bottom: 5px;
    left: 7px;
	visibility:hidden;
}
.custom-radio { position: relative; }
.item-inner{
    border: 1px solid #dee8f1;
    border-radius: 5px;
    overflow: hidden;
}

.footer{
   /* position: absolute;*/
    bottom: 0;
    background: #fff;
    width: 100%;
    max-width: 420px;
    text-align: center;
    position: fixed;
}

.footer a{
    background-color: #38220b;
    color: #fff;
    font-family: 'gothic';
    text-decoration: none;
    width: 35%;
    display: inline-block;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    margin: 10px;
}

.loader, .error-screen{
    display: flex;
    height: 40%;
    justify-content: center;
    align-items: center;
}

.loader div, .error-screen div{
    text-align: center;
    color: #38220b;
}

.error-screen div a{
    display: block;
    margin: 10px auto;
    font-family: 'gothic';
    font-weight: bold;
    width: 100px;
    text-decoration: none;
    border-radius: 5px;
    border: none;
    background-color:  #f9a753;
    color: #fff;
}

#profile .fullname{
    color: #38220b;
    text-align: center;
    font-weight: bold;
}

#profile .edit-btn{
    background-color:  #fff;
    color: #f9a753;
    border: 2px solid #f9a753;
    width: auto;
    display: block;
    margin: auto;
    border-radius: 5px;
    padding: 2px 15px;
    font-size: 13px;
}

.subscription-list{
    margin-top: 20px;
}

.subscription-list .sub-item{
    margin: 10px;
    padding: 5px;
    border: 2px solid #dee8f1;
    border-radius: 5px;
    font-size: 20px;
}

.subscription-list .sub-item img{
    width : 100%;
    border-radius: 5px;
}

.subscription-list .sub-item p{
    color: #38220b;
    text-align: center;
    font-weight: bold;
    font-size: 10px;
    margin: 10px auto;
}

.subscription-list .sub-item a{
    color:  #fff;
    text-decoration: none;
    text-align: center;
    background-color: #f9a753;
    border: 2px solid #f9a753;
    width: auto;
    display: block;
    font-weight: bold;
    margin: 20px auto;
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 13px;
}

.subscription-list .sub-item .note{
    color: #e40f0f
}

.active-sub .bar{
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin: 20px auto;
    height: 40px;
    line-height: 40px;
    background-color: #38220b;
    color: #fff;
    width: 90%;
    border-radius: 5px;
    font-family: 'gothic';
}

.auto-renew-jumbotron{
    padding: 5px;
    border: 2px solid #dee8f1;
    border-radius: 5px;
    width: 90%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 190px;
}

.auto-renew-jumbotron h5{
    color: #38220b;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.auto-renew-jumbotron div.in{
    text-align: center;
}

.auto-renew-jumbotron div p{
    font-size: 12px;
    color: #38220b;
    text-align: center;
}

#profile .member{
    background: #f9a753;
    color: #fff;
    border-radius: 5px;
    font-size: 13px;
    width: 140px;
    text-align: center;
    padding: 3px;
    margin: 10px auto;
}

.sub-range p{
    color: #38220b;
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
}

#subscription h4{
    color: #38220b;
    text-align: center;
    font-weight: bold;
}

#subscription h2{
    color: #38220b;
    text-align: center;
    margin: 50px auto;
    font-weight: bold;
}

.sub-range .dt-con{
    margin: auto;
    text-align: center;
}

.sub-range a{
    text-align: center;
    text-decoration: none;
    padding: 5px 10px;
}

.sub-range p span{
    text-decoration: underline;
}

.switch-icon{
    color: #fff;
    font-weight: bold;
    padding: 0px 5px;
}

.dropdown-menu{
    height: 300px;
    overflow: auto;
}

.nb{
    font-weight: bold;
    color: #ed2830;
}

.empty-list{
    display: flex;
    width: 100%;
    height: 300px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    color: #38220b;
}


.points-cnt .row > div {    padding: 0 2px;  margin-bottom: 10px;  }
.points-box {
    text-align: left;  
    height:100%;   
    background: #fff; 
    border: 2px solid #dee8f1;
    border-radius: 8px;
    overflow: hidden;
}
.points-box h3 {  font-family: 'gothic'; color: #38220b; font-size: 15px; font-weight: bold; padding: 5px 5px 7px 5px;   }
.points-box p {    font-family: 'gothic';    font-size: 11px; font-weight: bold;    max-width: 80%; color: #38220b; }
.points-box img{ height: 115px; display: block; margin: auto; margin-bottom: 10px;}
.points-box .pb-inner { 
    display: flex;
    padding: 5px;
    align-items: center;
 }

 .points-box .pb-inner .l{
     width: calc(100% - 30px);
     margin-right: 5px;
     padding: 4px;
 } 
 .close-modal{
    background: none;
    border: none;
    float: right;
    font-weight: bold;
    font-size: 20px;
    color: #38220b;
 }

 .points-pill div{
    text-align: center;
 }

 .points-pill div small, .points-pill div h5, .points-pill div p{
    color: #38220b;
    font-weight: bold;
 }

 .rdm-hdr{
    background-color: #38220b;
    color: #fff;
    text-align: left;
    padding: 5px;
    font-weight: bold;
 }

 .rdm-list li{
    padding: 5px 0;
    color: #38220b;
    text-align: left;
 }

 .qr-blk{
    text-align: center;
 }
 .qr-blk .manual-input{
    background-color: #38220b;
    border-radius: 10px;
    padding: 10px;
 }

 .qr-blk .manual-input label{
    display: block;
    text-align: center;
    color: #fff;
    font-weight: bold;
 }

 .qr-blk .manual-input input{
    text-align: center;
 }

 .prd-blk img{
    margin: auto;
    display: block;
    width: 80%;
 }

 .prd-blk h6{
    text-align: center;
    width: 80%;
    margin: 29px auto;
    font-size: 12px;
    color: #38220b;
 }

 .prd-blk h4{
    text-align: center;
    width: 80%;
    margin: 29px auto;
    font-size: 20px;
    color: #38220b;
    font-weight: bold;
 }

 .prd-blk h4 span{
    color: #f00;    
    font-size: 13px;
    padding-left: 5px;

 }

 #transactions .tab-pane{
    height: 60vh;
    overflow: auto;
 }
 .table-item{
    width: 90%;
    margin: 10px auto;
    max-width: 350px;
    padding: 5px;
    background: #fff;
}

.table-item .item-tit{
    color: #fff;
    background-color: #38220b;
    
    height: 30px;
    align-items: center;
    padding-left: 10px;

}

.table-item .item-tit h5{
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: bold;
}

.table-item .item-tit h2{
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: bold;

}

.table-item .item-desc{
    font-size: 12px;
}

.table-item .item-desc td{
    background: rgb(68,15,107,0.1);
    border: 1px solid #fff;
    width: 50%;
}

.table-item .item-desc td.completed{
    background: rgba(78 ,226 ,41,0.1);
}

.table-item .item-desc td.reversed{
    background: rgba(226 ,41 ,67,0.1);
}


.table-item .item-desc td:first-child{
    text-align: right;
    padding: 3px 5px;
}

.table-item .item-desc td:last-child{
    text-align: right;
    padding: 3px 5px;
}

.t-item{
    width: 95%;
    margin: 10px auto;
    border : 2px solid #dee8f1;
    border-radius: 10px;
    padding: 5px;
    color: #38220b;
    display: flex;
    min-height: 140px;
}

.t-item.claims{
    min-height: auto;
}

.t-item p{
    margin-bottom: 5px;
    color: #38220b;
    font-size: 12px;
}

.t-item h4{
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 16px;
}

.t-item .t-desc{
    width: 80%;
}
.t-item .t-cost{
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #f9a753;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
     box-shadow: 0 0 0 0;
}

.notify{
    position: relative;
}
.notify span{
    background-color: #f00;
    width: 10px;
    display: block;
    height: 10px;
    border-radius: 50vw;
    position: absolute;
    top: 2px;
    right: 0px;
}

.tab-pane{
    min-height: 260px;
}
.bundle-list .bundle{
    border: 1px solid #38220b;
    margin: 5px;
    border-radius: 5px;
    height: 50px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bundle-list .bundle h4{
    font-size: 16px;
    margin-bottom: 0;
    font-weight: bold;
    color: #38220b;
}

.bundle-list .bundle h4 small{
    font-weight: normal;
    font-size: 13px;
}
.bundle-action{
    text-align: center;
    width: 350px;
}
.bundle-action button{
    width: 45%;
    margin: 5px;
    font-size: 18px;
    font-weight: bold;
    background-color: #38220b;
    border: 2px solid #38220b;
    color: #fff;
}
.bundle-action button.inverse{
    background-color:  #fff;
    color: #38220b;
}

.pop-footer {
    bottom: 0;
    left: 50%;
    padding: 2px;
    position: fixed;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.rp-itm-blk{
    padding-bottom: 103px;
}